import { useState, useLayoutEffect } from 'react';

function useContainerWidth(containerRef) {
  const [containerWidth, setWidth] = useState(0);

  useLayoutEffect(() => {
    const readWidth = () => {var _containerRef$current;
      setWidth(((_containerRef$current = containerRef.current) === null || _containerRef$current === void 0 ? void 0 : _containerRef$current.offsetWidth) || 0);
    };

    readWidth();

    window.addEventListener('resize', readWidth);
    return () => {
      window.removeEventListener('resize', readWidth);
    };
  }, []);

  return containerWidth;
}

export { useContainerWidth };