import { notificationActions } from "../../Notifications";
import { t } from "../../I18N";
import { RequestParams } from "../../utils/RequestParams";
import SettingsAPI from "../SettingsAPI";
import { actions } from "../../BasicReducer";

const saveSettings = (data) => (dispatch) =>
SettingsAPI.save(new RequestParams(data)).then((newSettings) => {
  dispatch(actions.set('settings/collection', newSettings));
  dispatch(notificationActions.notify(t('System', 'Settings updated'), 'success'));
});

export default saveSettings;