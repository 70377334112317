















export const parseRenderingError = (apiResponse) =>
apiResponse ?
{
  name: apiResponse.json.error || 'Unexpected error',
  message: apiResponse.json.prettyMessage,
  requestId: apiResponse.json.requestId,
  code: apiResponse.status
} :
null;