import util from 'util';
import { isObject, isString } from 'lodash';
import ValidationError from 'ajv/dist/runtime/validation_error';


export const isBlobFile = (file) =>
isObject(file) && isString(file.data);

// Thanks to https://stackoverflow.com/questions/54738221/typescript-array-find-possibly-undefind
export function ensure(argument, message) {
  if (argument === undefined || argument === null || !argument) {
    throw new TypeError(message || 'Promised type was not provided!');
  }

  return argument;
}

export function wrapValidator(validator) {
  return async (value) => {
    try {
      return validator(value);
    } catch (error) {
      if (error) {
        const e = new ValidationError(error.errors);
        e.message = util.inspect(error, false, null);
        e.stack = error.stack;
        throw e;
      }
      throw error;
    }
  };
}

export async function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}